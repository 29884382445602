import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-landing-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
var ref;
import { Loader } from "@googlemaps/js-api-loader";
import { LoggerInstance } from "@vfit/shared/providers";
var loader = {
    apiKey: (ref = process.env) === null || ref === void 0 ? void 0 : ref["NX_GOOGLE_MAPS_API_KEY"],
    version: "weekly",
    libraries: [
        "places"
    ],
    language: "it"
};
var getLoader = function() {
    var country = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "it";
    return _object_spread_props(_object_spread({}, loader), {
        region: country
    });
};
var getValueSafely = function(value) {
    var toUpperCase = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var v = value !== undefined && value !== null && typeof value !== "string" ? value.toString() : value;
    // eslint-disable-next-line no-nested-ternary
    return v ? toUpperCase ? v.toUpperCase() : v : "";
};
export var parseGeocoderResult = function(geocoder) {
    var toUpperCase = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ref, ref1, ref2, ref3;
    return geocoder.address_components.reduce(function(previous, current) {
        switch(true){
            case current.types.includes("administrative_area_level_3"):
                return _object_spread_props(_object_spread({}, previous), {
                    city: getValueSafely(current.long_name, toUpperCase)
                });
            case current.types.includes("postal_code"):
                return _object_spread_props(_object_spread({}, previous), {
                    postalCode: getValueSafely(current.long_name)
                });
            case current.types.includes("administrative_area_level_2"):
                return _object_spread_props(_object_spread({}, previous), {
                    province: getValueSafely(current.short_name, toUpperCase)
                });
            case current.types.includes("country"):
                return _object_spread_props(_object_spread({}, previous), {
                    region: getValueSafely(current.long_name)
                });
            case current.types.includes("route"):
                return _object_spread_props(_object_spread({}, previous), {
                    street: getValueSafely(current.long_name)
                });
            case current.types.includes("street_number"):
                return _object_spread_props(_object_spread({}, previous), {
                    streetNumber: getValueSafely(current.long_name)
                });
            default:
                return previous;
        }
    }, {
        latitude: getValueSafely(geocoder === null || geocoder === void 0 ? void 0 : (ref = geocoder.geometry) === null || ref === void 0 ? void 0 : (ref1 = ref.location) === null || ref1 === void 0 ? void 0 : ref1.lat()),
        longitude: getValueSafely(geocoder === null || geocoder === void 0 ? void 0 : (ref2 = geocoder.geometry) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.location) === null || ref3 === void 0 ? void 0 : ref3.lng()),
        placeId: getValueSafely(geocoder === null || geocoder === void 0 ? void 0 : geocoder.place_id)
    });
};
export var getPlacePredictions = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(input) {
        var country, mapsLoader, google, service, _args = arguments;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    country = _args.length > 1 && _args[1] !== void 0 ? _args[1] : "it";
                    mapsLoader = new Loader(getLoader(country));
                    _ctx.next = 4;
                    return mapsLoader.load();
                case 4:
                    google = _ctx.sent;
                    if (!google) {
                        _ctx.next = 9;
                        break;
                    }
                    service = new google.maps.places.AutocompleteService();
                    if (!service) {
                        _ctx.next = 9;
                        break;
                    }
                    return _ctx.abrupt("return", new Promise(function(resolve, reject) {
                        service.getPlacePredictions({
                            input: input,
                            componentRestrictions: {
                                country: country
                            },
                            types: [
                                "address"
                            ]
                        }, function(autocompletePredictions, status) {
                            if (status === google.maps.places.PlacesServiceStatus.OK) {
                                if (autocompletePredictions !== null) {
                                    return resolve(autocompletePredictions);
                                }
                                LoggerInstance.debug("No autocomplete predictions found");
                            } else {
                                LoggerInstance.debug("AutocompleteService failed due to: ".concat(status));
                            }
                            reject(new Error("AutocompleteService error"));
                        });
                    }));
                case 9:
                    return _ctx.abrupt("return", null);
                case 10:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getPlacePredictions(input) {
        return _ref.apply(this, arguments);
    };
}();
export var getGeocoderResults = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(lat, lng) {
        var country, mapsLoader, google, geocoder, latlng, _args = arguments;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    country = _args.length > 2 && _args[2] !== void 0 ? _args[2] : "it";
                    mapsLoader = new Loader(getLoader(country));
                    _ctx.next = 4;
                    return mapsLoader.load();
                case 4:
                    google = _ctx.sent;
                    if (!google) {
                        _ctx.next = 10;
                        break;
                    }
                    geocoder = new google.maps.Geocoder();
                    latlng = new google.maps.LatLng(lat, lng);
                    if (!(geocoder && latlng)) {
                        _ctx.next = 10;
                        break;
                    }
                    return _ctx.abrupt("return", new Promise(function(resolve, reject) {
                        geocoder.geocode({
                            location: latlng
                        }, function(geocoderResults, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                if (geocoderResults !== null) {
                                    var filteredResults = geocoderResults.filter(function(result) {
                                        return result.address_components.length >= 8;
                                    });
                                    if (filteredResults.length !== 0) {
                                        return resolve(filteredResults);
                                    }
                                    LoggerInstance.debug("No geocoder results found");
                                } else {
                                    LoggerInstance.debug("No geocoder results found");
                                }
                            } else {
                                LoggerInstance.debug("Geocoder failed due to: ".concat(status));
                            }
                            return reject(new Error("Geocoder error"));
                        });
                    }));
                case 10:
                    return _ctx.abrupt("return", null);
                case 11:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getGeocoderResults(lat, lng) {
        return _ref.apply(this, arguments);
    };
}();
export var getGeocoderResultByPlaceId = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(placeId) {
        var country, mapsLoader, google, geocoder, _args = arguments;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    country = _args.length > 1 && _args[1] !== void 0 ? _args[1] : "it";
                    mapsLoader = new Loader(getLoader(country));
                    _ctx.next = 4;
                    return mapsLoader.load();
                case 4:
                    google = _ctx.sent;
                    if (!google) {
                        _ctx.next = 9;
                        break;
                    }
                    geocoder = new google.maps.Geocoder();
                    if (!geocoder) {
                        _ctx.next = 9;
                        break;
                    }
                    return _ctx.abrupt("return", new Promise(function(resolve, reject) {
                        geocoder.geocode({
                            placeId: placeId
                        }, function(geocoderResults, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                if (geocoderResults !== null) {
                                    return resolve(geocoderResults[0]);
                                }
                                LoggerInstance.debug("No geocoder results found");
                            } else {
                                LoggerInstance.debug("Geocoder failed due to: ".concat(status));
                            }
                            return reject(new Error("Geocoder error"));
                        });
                    }));
                case 9:
                    return _ctx.abrupt("return", null);
                case 10:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getGeocoderResultByPlaceId(placeId) {
        return _ref.apply(this, arguments);
    };
}();
