import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-radius: ",
        ";\n  box-shadow: ",
        ";\n  gap: ",
        ";\n  padding: ",
        ";\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  margin: 0;\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  flex: 1;\n  display: flex;\n  gap : ",
        ";\n  @media (min-width: ",
        ") {\n    flex-direction: column;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  flex: 1;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n\n  &:first-child {\n    margin-top: 0;\n  }\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  p {\n    :first-child {\n      margin-top: 0;\n    }\n\n    :last-child {\n      margin-bottom: 0;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  flex-shrink: 0;\n  height: ",
        ";\n  width: ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, pxToRem, colors, getTypography, shadows } from "@vfit/shared/themes";
export var Container = styled.article.withConfig({
    componentId: "sc-185bd2a-0"
})(_templateObject(), colors.$ffffff, pxToRem(6), shadows.card_overlay, pxToRem(16), pxToRem(20, 16));
export var Title = styled.h2.withConfig({
    componentId: "sc-185bd2a-1"
})(_templateObject1(), getTypography("body1.bold"));
export var ContentContainer = styled.section.withConfig({
    componentId: "sc-185bd2a-2"
})(_templateObject2(), pxToRem(16), breakpoints.tablet);
export var Content = styled.div.withConfig({
    componentId: "sc-185bd2a-3"
})(_templateObject3());
export var Description = styled.section.withConfig({
    componentId: "sc-185bd2a-4"
})(_templateObject4(), getTypography("body2.regular"));
export var ImgWrapper = styled.div.withConfig({
    componentId: "sc-185bd2a-5"
})(_templateObject5(), pxToRem(48), pxToRem(48));
