import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  &.street-number-enter {\n    animation: ",
        " 0.5s forwards;\n  }\n\n  &.street-number-exit {\n    animation: ",
        " 0.5s forwards;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { animations } from "@vfit/shared/themes";
export var fadeAnimation = css(_templateObject(), animations.fadeInDown, animations.fadeOutUp);
